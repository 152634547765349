import {
  getPositions,
  getPosition,
  createPosition,
  updatePosition,
} from "@/api/position";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all positions
    getPositions({ commit }, query) {
      return new Promise((resolve, reject) => {
        getPositions(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get position by id
    getPosition({ commit }, id) {
      return new Promise((resolve, reject) => {
        getPosition(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create position
    createPosition({ commit }, position) {
      return new Promise((resolve, reject) => {
        createPosition(position)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update position
    updatePosition({ commit }, position) {
      return new Promise((resolve, reject) => {
        updatePosition(position)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
