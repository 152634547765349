import request from "@/utils/request";

// get all accountTypes
export function getAccountTypes(query) {
  return request({
    url: "/account-type/list",
    method: "post",
    params: query,
  });
}

// get accountType by id
export function getAccountType(id) {
  return request({
    url: "/account-type/info",
    method: "post",
    params: { id },
  });
}

// create accountType
export function createAccountType(accountType) {
  return request({
    url: "/account-type/store",
    method: "post",
    data: accountType,
  });
}

// update accountType
export function updateAccountType(accountType) {
  return request({
    url: "/account-type/update",
    method: "post",
    data: accountType,
  });
}
