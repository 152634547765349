import { getMapConfig, updateMapConfig } from "@/api/map-config";
export default {
  namespaced: true,
  state: {
    mapConfig: {},
  },
  getters: {},
  mutations: {
    SET_MAP_CONFIG(state, mapConfig) {
      state.mapConfig = mapConfig;
    },
  },
  actions: {
    // set mapConfig
    setMapConfig({ commit }, mapConfig) {
      commit("SET_MAP_CONFIG", mapConfig);
    },
    // update mapConfig
    updateMapConfig({ commit }, mapConfig) {
      return new Promise((resolve, reject) => {
        updateMapConfig(mapConfig)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
