import request from "@/utils/request";

// get all employmentTypes
export function getEmploymentTypes(query) {
  return request({
    url: "/employeement-type/list",
    method: "post",
    params: query,
  });
}

// get employmentType by id
export function getEmploymentType(id) {
  return request({
    url: "/employeement-type/info",
    method: "post",
    params: { id },
  });
}

// create employmentType
export function createEmploymentType(employmentType) {
  return request({
    url: "/employeement-type/store",
    method: "post",
    data: employmentType,
  });
}

// update employmentType
export function updateEmploymentType(employmentType) {
  return request({
    url: "/employeement-type/update",
    method: "post",
    data: employmentType,
  });
}
