import {
  getEmploymentTypes,
  getEmploymentType,
  createEmploymentType,
  updateEmploymentType,
} from "@/api/employment-type";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all employmentTypes
    getEmploymentTypes({ commit }, query) {
      return new Promise((resolve, reject) => {
        getEmploymentTypes(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get employmentType by id
    getEmploymentType({ commit }, id) {
      return new Promise((resolve, reject) => {
        getEmploymentType(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create employmentType
    createEmploymentType({ commit }, employmentType) {
      return new Promise((resolve, reject) => {
        createEmploymentType(employmentType)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update employmentType
    updateEmploymentType({ commit }, employmentType) {
      return new Promise((resolve, reject) => {
        updateEmploymentType(employmentType)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
