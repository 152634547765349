import {
  getSkills,
  getSkill,
  createSkill,
  updateSkill,
} from "@/api/skill";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all skills
    getSkills({ commit }, query) {
      return new Promise((resolve, reject) => {
        getSkills(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get skill by id
    getSkill({ commit }, id) {
      return new Promise((resolve, reject) => {
        getSkill(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create skill
    createSkill({ commit }, skill) {
      return new Promise((resolve, reject) => {
        createSkill(skill)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update skill
    updateSkill({ commit }, skill) {
      return new Promise((resolve, reject) => {
        updateSkill(skill)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
