import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import employee from './employee'
import verticalMenu from './vertical-menu'
import company from './company'
import job from './job'
import customer from './customer'
import dashboard from './dashboard'
import category from './category'
import industry from './industry'
import accountType from './account-type'
import employmentType from './employment-type'
import bank from './bank'
import country from './country'
import designation from './designation'
import duty from './duty'
import field from './field'
import position from './position'
import skill from './skill'
import sector from './sector'
import roster from './roster'
import shift from './shift'
import role from './role'
import question from './question'
import user from './user'
import location from './location'
import subLocation from './sub-location'
import mapConfig from './map-config'
import globalConfig from './global-config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth, // Auth module
    employee, // Employee module
    company, // Company module
    job, // Job module
    customer, // Customer module
    dashboard, // Dashboard module
    category, // Category module
    industry, // Industry module
    accountType, // AccountType module
    employmentType, // EmploymentType module
    bank, // Bank module
    country, // Country module
    designation, // Designation module
    duty, // Duty module
    field, // Field module
    position, // Position module
    skill, // Skill module
    sector, // Sector module
    roster, // Roster module
    shift, // Shift module
    role, // Role module
    question, // Question module
    user, //User module
    location, //Location module
    subLocation, // subLocation module
    mapConfig, // MapConfig module
    globalConfig, // GlobalConfig module
  },
  // eslint-disable-next-line no-undef
  strict: process.env.DEV,
});
