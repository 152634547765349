export default [
  {
    path: "/super-admin-list",
    name: "Super Admin List",
    meta: {
      resource: "Super Admin",
      action: "list",
    },
    component: () => import("@/views/users/index.vue"),
  },
  {
    path: "/view-user/:id",
    name: "Super admin's information ",
    meta: {
      resource: "Super Admin",
      action: "view",
    },
    component: () => import("@/views/users/view.vue"),
  },
  {
    path: "/user-create",
    name: "Create Super Admin",
    meta: {
      resource: "Super Admin",
      action: "create",
    },
    component: () => import("@/views/users/create.vue"),
  },
  {
    path: "/edit-user/:id",
    name: "Edit Super Admin",
    meta: {
      resource: "Super Admin",
      action: "update",
    },
    component: () => import("@/views/users/edit.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: {
      resource: "Dashboard",
      action: "show menu",
    },
    component: () => import("@/views/notification/index.vue"),
  },
];
