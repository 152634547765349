import request from "@/utils/request";

// get all skills
export function getSkills(query) {
  return request({
    url: "/employee/skill-list",
    method: "post",
    params: query,
  });
}

// get skill by id
export function getSkill(id) {
  return request({
    url: "/skill/info",
    method: "post",
    params: { id },
  });
}

// create skill
export function createSkill(skill) {
  return request({
    url: "/employee/skill-create",
    method: "post",
    data: skill,
  });
}

// update skill
export function updateSkill(skill) {
  return request({
    url: "/employee/skill-update",
    method: "post",
    data: skill,
  });
}
