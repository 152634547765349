  import {
    companyCustomerListWithFilter,
    companyRegistration,
    getCompanies,
    getCompaniesAllInfo,
    getCompany,
    getSisterConcernCompany,
    updateCompany,
    companyUserList,
    createCompanyUser,
    getCompanyUser,
    updateCompanyUser,
    getOnlyCompanyList,
    getCompaniesForDropdown,
    importCompany,
  } from "@/api/company";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all companies
    getCompanies({ commit }, query) {
      return new Promise((resolve, reject) => {
        getCompanies(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCompaniesAllInfo({ commit }, query) {
      return new Promise((resolve, reject) => {
        getCompaniesAllInfo(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get all companies for dropdown
    getCompaniesForDropdown({ commit }) {
      return new Promise((resolve, reject) => {
        getCompaniesForDropdown()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            
            reject(error);
          });
      });
    },

    // get company by id
    getCompany({ commit }, id) {
      return new Promise((resolve, reject) => {
        getCompany(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getOnlyCompanyList({ commit }) {
      return new Promise((resolve, reject) => {
        getOnlyCompanyList()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    getSisterConcernCompany({ commit }, id) {
      return new Promise((resolve, reject) => {
        getSisterConcernCompany(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // company customer list
    // companyCustomerList({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     companyCustomerList(id)
    //       .then((response) => {
    //         const { data } = response;
    //         resolve(data);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    // get company customer list with filter
    companyCustomerListWithFilter({ commit }, query) {
      return new Promise((resolve, reject) => {
        companyCustomerListWithFilter(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    companyRegistration({ commit }, data) {
      return new Promise((resolve, reject) => {
        companyRegistration(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // update company
    updateCompany({ commit }, company) {
      return new Promise((resolve, reject) => {
        updateCompany(company)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get company user list
    companyUserList({ commit }, query) {
      return new Promise((resolve, reject) => {
        companyUserList(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // create company user
    createCompanyUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        createCompanyUser(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get a company user by id
    getCompanyUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        getCompanyUser(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update company user
    updateCompanyUser({ commit }, companyUser) {
      return new Promise((resolve, reject) => {
        updateCompanyUser(companyUser)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // import company
    importCompany({ commit }, data) {
      return new Promise((resolve, reject) => {
        importCompany(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            
            reject(error);
          });
      });
    },
  },
};
