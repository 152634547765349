import request from "@/utils/request";

// get all countries
export function getCountries(query) {
  return request({
    url: "/country/list",
    method: "post",
    params: query,
  });
}

// get country by id
export function getCountry(id) {
  return request({
    url: "/country/info",
    method: "post",
    params: { id },
  });
}

// create country
export function createCountry(country) {
  return request({
    url: "/country/store",
    method: "post",
    data: country,
  });
}

// update country
export function updateCountry(country) {
  return request({
    url: "/country/update",
    method: "post",
    data: country,
  });
}
