import {
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
} from "@/api/category";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all categories
    getCategories({ commit }, query) {
      return new Promise((resolve, reject) => {
        getCategories(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get category by id
    getCategory({ commit }, id) {
      return new Promise((resolve, reject) => {
        getCategory(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create category
    createCategory({ commit }, category) {
      return new Promise((resolve, reject) => {
        createCategory(category)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update category
    updateCategory({ commit }, category) {
        return new Promise((resolve, reject) => {
            updateCategory(category)
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
  },
};
