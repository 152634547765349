export default [
  {
    path: "/create-roster",
    name: "create-roster",
    meta: {
      resource: "Roster",
      action: "create",
    },
    component: () => import("@/views/roster/index.vue"),
  },
  {
    path: "/assigned-roster",
    name: "assigned-roster",
    meta: {
      resource: "Roster",
      action: "view assigned roster",
    },
    component: () => import("@/views/roster/uploaded-roster.vue"),
  },
  {
    path: "/published-roster",
    name: "published-roster",
    meta: {
      resource: "Roster",
      action: "view published roster",
    },
    component: () => import("@/views/roster/published-roster.vue"),
  },
];