export const initialAbility = [
  {
    action: "read",
    subject: "Auth",
  },

  {
    action: "show menu",
    subject: "Dashboard",
  },
  // {
  //   action: "manage",
  //   subject: "all",
  // },
];

export const _ = undefined;
