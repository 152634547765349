export default [
  // *===============================================---*
  // *--------- Employee ---- ---------------------------------------*
  // *===============================================---*

  {
    path: "/account-types",
    name: "account-types",
    component: () => import("@/views/account-type/index.vue"),
    meta: {
      resource: "Bank Account Type",
      action: "list",
    },
  },
  {
    path: "/banks",
    name: "banks",
    component: () => import("@/views/bank/index.vue"),
    meta: {
      resource: "Bank",
      action: "list",
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("@/views/category/index.vue"),
    meta: {
      resource: "Category",
      action: "list",
    },
  },
  {
    path: "/countries",
    name: "countries",
    component: () => import("@/views/country/index.vue"),
    meta: {
      resource: "Country",
      action: "list",
    },
  },
  {
    path: "/designations",
    name: "designations",
    component: () => import("@/views/designation/index.vue"),
    meta: {
      resource: "Designation",
      action: "list",
    },
  },
  {
    path: "/job-roles",
    name: "job-roles",
    component: () => import("@/views/duty/index.vue"),
    meta: {
      resource: "Job Role",
      action: "list",
    },
  },
  {
    path: "/employment-types",
    name: "employment-types",
    component: () => import("@/views/employment-type/index.vue"),
    meta: {
      resource: "Employment Type",
      action: "list",
    },
  },
  {
    path: "/fields",
    name: "fields",
    component: () => import("@/views/field/index.vue"),
    meta: {
      resource: "Fields",
      action: "list",
    },
  },
  {
    path: "/industries",
    name: "industries",
    component: () => import("@/views/industry/index.vue"),
    meta: {
      resource: "Industry",
      action: "list",
    },
  },
  {
    path: "/locations",
    name: "locations",
    component: () => import("@/views/location/index.vue"),
    meta: {
      resource: "Location",
      action: "list",
    },
  },

  {
    path: "/sub-locations",
    name: "sub-locations",
    component: () => import("@/views/sub-location/index.vue"),
    meta: {
      resource: "Sub Location",
      action: "list",
    },
  },
  {
    path: "/positions",
    name: "positions",
    component: () => import("@/views/position/index.vue"),
    meta: {
      resource: "Position",
      action: "list",
    },
  },
  // {
  //   path: "/sectors",
  //   name: "sectors",
  //   component: () => import("@/views/sector/index.vue"),
  //   meta: {
  //     resource: "Sector",
  //     action: "list",
  //   },
  // },
  {
    path: "/skills",
    name: "skills",
    component: () => import("@/views/skill/index.vue"),
    meta: {
      resource: "Skill",
      action: "list",
    },
  },
  {
    path: "/shifts",
    name: "shifts",
    component: () => import("@/views/shift/index.vue"),
    meta: {
      resource: "Shift Configuration",
      action: "view",
    },
  },
  {
    path: "/roles",
    name: "roles",
    meta: {
      resource: "Role Permission",
      action: "list",
    },
    component: () => import("@/views/role/index.vue"),
  },
  {
    path: "/roles/view/:roleId",
    name: "roles/view",
    component: () => import("@/views/role/view.vue"),
    meta: {
      resource: "Role Permission",
      action: "view",
      navActiveLink: 'roles',
    },
  },
  {
    path: "/global-config",
    name: "global-config",
    component: () => import("@/views/global-config/index.vue"),
    meta: {
      resource: "Global Configuration",
      action: "view",
      navActiveLink: 'global-config',
    },
  },
];


