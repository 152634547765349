import {
  applyJob,
  changeApplicanStatus,
  deleteJob,
  getApplicants,
  getAppliedJobs,
  getJob,
  getJobHistory,
  getJobTemplete,
  getJobs,
  getJobsDraft,
  publishJob,
  storeJob,
  storeJobTemplete,
  updateJob,
  updateStatus,
  updatePriority,
  apply,
} from "@/api/job";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all companies
    getJobs({ commit }, query) {
      return new Promise((resolve, reject) => {
        getJobs(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getJobsDraft({ commit }, query) {
      return new Promise((resolve, reject) => {
        getJobsDraft(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get job hsiory
    getJobHistory({ commit }, query) {
      return new Promise((resolve, reject) => {
        getJobHistory(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // change applicant status
    changeApplicanStatus({ commit }, query) {
      return new Promise((resolve, reject) => {
        changeApplicanStatus(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get job by id
    getJob({ commit }, query) {
      return new Promise((resolve, reject) => {
        getJob(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteJob({ commit }, id) {
      return new Promise((resolve, reject) => {
        deleteJob(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // store job
    storeJob({ commit }, data) {
      return new Promise((resolve, reject) => {
        storeJob(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    storeJobTemplete({ commit }, data) {
      return new Promise((resolve, reject) => {
        storeJobTemplete(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getJobTemplete({ commit }, query) {
      return new Promise((resolve, reject) => {
        getJobTemplete(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update job
    updateJob({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateJob(data, id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // publish job
    publishJob({ commit }, data) {
      return new Promise((resolve, reject) => {
        publishJob(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // apply job
    applyJob({ commit }, data) {
      return new Promise((resolve, reject) => {
        applyJob(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    apply({ commit }, data) {
      return new Promise((resolve, reject) => {
        apply(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    // view applicants
    getApplicants({ commit }, id) {
      return new Promise((resolve, reject) => {
        getApplicants(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // update job status
    updateStatus({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateStatus(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get applied jobs
    getAppliedJobs({ commit }, query) {
      return new Promise((resolve, reject) => {
        getAppliedJobs(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update job proiority
    updatePriority({ commit }, data) {
      return new Promise((resolve, reject) => {
        updatePriority(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    }
  },
};
