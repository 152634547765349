import {
  getCustomers,
  getCustomer,
  createCustomer,
  createContact,
  updateCustomer,
  updateContact,
  getCompanyCustomers,
  createLocation,
  deleteLocation,
  getCustomersForDropdown,
} from "@/api/customer";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all customers
    getCustomers({ commit }, query) {
      return new Promise((resolve, reject) => {
        getCustomers(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get customer by id
    getCustomer({ commit }, id) {
      return new Promise((resolve, reject) => {
        getCustomer(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get all customers for dropdown filter by company
    getCustomersForDropdown({ commit }, user_id) {
      return new Promise((resolve, reject) => {
        getCustomersForDropdown(user_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            
            reject(error);
          });
      });
    },

    // create customer
    createCustomer({ commit }, customer) {
      return new Promise((resolve, reject) => {
        createCustomer(customer)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create customer location
    createLocation({ commit }, location) {
      return new Promise((resolve, reject) => {
        createLocation(location)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    },

    // create Contact
    createContact({ commit }, contact) {
      return new Promise((resolve, reject) => {
        createContact(contact)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update customer
    updateCustomer({ commit }, customer) {
      return new Promise((resolve, reject) => {
        updateCustomer(customer)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update contacts
    updateContact({ commit }, contacts) {
      return new Promise((resolve, reject) => {
        updateContact(contacts)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // company customer list
    getCompanyCustomers({ commit }, query) {
      return new Promise((resolve, reject) => {
        getCompanyCustomers(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // delete customer location and break
    deleteLocation({ commit }, data) {
      return new Promise((resolve, reject) => {
        deleteLocation(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
};
