import request from "@/utils/request";
// update mapConfig
export function getXeroServiceStatus() {
  return request({
    url: "/xero-service",
    method: "post",
  });
}

// get xero env list
export function getXeroEnv() {
  return request({
    url: "/env-list",
    method: "post",
  });
}

// update xero env
export function updateXeroEnv(keys) {
  return request({
    url: "/env-update",
    method: "post",
    data: { keys: keys },
  });
}
