import {
  getLocations,
  getLocation,
  createLocation,
  updateLocation,
  getAllLocations,
  getLocationsDropdown,
  getClientLocations
} from "@/api/location";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get locations list
    getLocations({ commit }, query) {
      return new Promise((resolve, reject) => {
        getLocations(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get locations list for dropdown
    getLocationsDropdown({ commit }, query) {
      return new Promise((resolve, reject) => {
        getLocationsDropdown(query)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get all locations for dropdown
    getAllLocations({ commit }) {
      return new Promise((resolve, reject) => {
        getAllLocations({ all: true })
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get location by id
    getLocation({ commit }, id) {
      return new Promise((resolve, reject) => {
        getLocation(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get client location by id
    getClientLocations({ commit }, id) {
      return new Promise((resolve, reject) => {
        getClientLocations(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create location
    createLocation({ commit }, location) {
      return new Promise((resolve, reject) => {
        createLocation(location)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update location
    updateLocation({ commit }, location) {
      return new Promise((resolve, reject) => {
        updateLocation(location)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
