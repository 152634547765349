// toast message shwoing function
import Vue from "vue";
import Toast from "vue-toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
Vue.use(Toast);
export const toast = (title, icon, type, message) => {
  Vue.$toast({
    component: ToastificationContent,
    position: "top-right",
    props: {
      title: title,
      icon: icon,
      variant: type,
      text: message,
    },
  });
};
