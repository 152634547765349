import VueCompositionAPI from '@vue/composition-api'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import Vue from 'vue'

import i18n from '@/libs/i18n'
import App from './App.vue'
import router from './router'
import store from './store'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/clipboard'
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'
import '@/libs/tour'
import '@/libs/vue-select'
import '@axios'


// Axios Mock Adapter
import '@/@fake-db/db'

import vueDebounce from "vue-debounce";


Vue.use(vueDebounce, {
  listenTo: ["input", "keyup"],
  defaultTime: "300ms",
  lock: true,
});
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import VueMeta from 'vue-meta'
 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

import axios from 'axios'
import { VUE_APP_BASE_URL } from '@/utils/var'
import * as VueGoogleMaps from 'vue2-google-maps'

// get map api key from api in async function
async function getMapApiKey() {
  const response = await axios.post(`${VUE_APP_BASE_URL}/get-map-key`)
  return response.data.data
}
// set map api key to map_key variable
// if promise is not resolved, do not execute the code below
getMapApiKey().then((data) => {
  let key = data.map_key
  store.dispatch('mapConfig/setMapConfig', data)
  Vue.use(VueGoogleMaps, {
    load: {
      key: key,
      region: 'AU',
      libraries: 'places', // This is required if you use the Autocomplete plugin
    },
  })
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
