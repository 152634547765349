import {
  getCountries,
  getCountry,
  createCountry,
  updateCountry,
} from "@/api/country";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all countries
    getCountries({ commit }, query) {
      return new Promise((resolve, reject) => {
        getCountries(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get country by id
    getCountry({ commit }, id) {
      return new Promise((resolve, reject) => {
        getCountry(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create country
    createCountry({ commit }, country) {
      return new Promise((resolve, reject) => {
        createCountry(country)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update country
    updateCountry({ commit }, country) {
      return new Promise((resolve, reject) => {
        updateCountry(country)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
