import request from "@/utils/request";

// get total
export function getTotal() {
  return request({
    url: "/dashboard/count-list",
    method: "post",
  });
}

export function getEmployeeEngagement() {
  return request({
    url: "/dashboard/employee-engage-free-job",
    method: "post",
  });
}

// get customer by id
export function timesheetHoursCount(company_id) {
  return request({
    url: "/dashboard/timesheet-hours-count",
    method: "post",
    params: { company_id },
  });
}
export function customerTimesheetHoursCountMonths(customer_id) {
  return request({
    url: "/dashboard/customer-timesheet-hours-count-months",
    method: "post",
    params: { customer_id },
  });
}
export function companyWiseEmployeeCount(company_id) {
  return request({
    url: "/dashboard/company-wise-employee-count",
    method: "post",
    params: { company_id },
  });
}

// dashboard/employee-document-expire
export function employeeDocumentExpire(user_id) {
  return request({
    url: "/dashboard/employee-document-expire",
    method: "post",
    params: { user_id },
  });
}

// /dashboard/customer-dashboard-data
export function customerDashboardData() {
  return request({
    url: "/dashboard/customer-dashboard-data",
    method: "post",
  });
}

// get employee document expire
export function getEmployeesExpiry(query) {
  return request({
    url: "/dashboard/document-expire-list",
    method: "post",
    params: query,
  });
}