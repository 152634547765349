import request from "@/utils/request";

// get all banks
export function getBanks(query) {
  return request({
    url: "/bank/list",
    method: "post",
    params: query,
  });
}

// get bank by id
export function getBank(id) {
  return request({
    url: "/bank/info",
    method: "post",
    params: { id },
  });
}

// create bank
export function createBank(bank) {
  return request({
    url: "/bank/store",
    method: "post",
    data: bank,
  });
}

// update bank
export function updateBank(bank) {
  return request({
    url: "/bank/update",
    method: "post",
    data: bank,
  });
}
