import request from "@/utils/request";

// get all companies
export function getCompanies(query) {
  return request({
    url: "/company-list",
    method: "post",
    params: query,
  });
}

// get all companies
export function getCompaniesAllInfo(query) {
  return request({
    url: "/company/list",
    method: "post",
    params: query,
  });
}

// get all companies
export function getOnlyCompanyList() {
  return request({
    url: "/company/only-company",
    method: "post",
  });
}

// get all companies for dropdown
export function getCompaniesForDropdown() {
  return request({
    url: "/company-dropdown",
    method: "post",
  });
}

// get company by id
export function getCompany(id) {
  return request({
    url: "/company/info",
    method: "post",
    params: { id },
  });
}
export function getSisterConcernCompany(id) {
  return request({
    url: "/company/company-customer-list",
    method: "post",
    params: { id },
  });
}
// company customer list
// export function companyCustomerList(id) {
//   return request({
//     url: "/company/customer-list",
//     method: "post",
//     params: { id },
//   });
// }
// get company customer list with filter
export function companyCustomerListWithFilter(query) {
  return request({
    url: "/company/customer-list",
    method: "post",
    params: query,
  });
}
// company registration
export function companyRegistration(data) {
  return request({
    url: "/company/registration",
    method: "post",
    data: data,
  });
}
// update customer
export function updateCompany(company) {
  return request({
    url: "/company/update",
    method: "post",
    data: company,
  });
}

// get company user list
export function companyUserList(query) {
  return request({
    url: "/supervisor/list",
    method: "post",
    params: query,
  });
}

// create company user
export function createCompanyUser(data) {
  return request({
    url: "/supervisor/store",
    method: "post",
    data: data,
  });
}

// get a company user by id
export function getCompanyUser(id) {
  return request({
    url: "/supervisor/details",
    method: "post",
    params: { id },
  });
}

// update company user
export function updateCompanyUser(data) {
  return request({
    url: "/supervisor/update",
    method: "post",
    data: data,
  });
}

// import company 
export function importCompany(data) {
  return request({
    url: "/company-customer-employee-import",
    method: "post",
    data: data,
  });
}