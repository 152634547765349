import request from "@/utils/request";
import { ex } from "@fullcalendar/core/internal-common";

// get all customers
export function getCustomers(query) {
  return request({
    url: "/customer/list",
    method: "post",
    params: query,
  });
}

// get customer by id
export function getCustomer(id) {
  return request({
    url: "/customer/info",
    method: "post",
    params: { id },
  });
}

// get all customers for dropdown filter by company
export function getCustomersForDropdown(query) {
  return request({
    url: "/customer-dropdown",
    method: "post",
    params: query,
  });
}

// create customer
export function createCustomer(customer) {
  return request({
    url: "/customer/store",
    method: "post",
    data: customer,
  });
}

// create Contact
export function createContact(contact) {
  return request({
    url: "/customer/contact-store",
    method: "post",
    data: contact,
  });
}

// update customer
export function updateCustomer(customer) {
  return request({
    url: "/customer/update",
    method: "post",
    data: customer,
  });
}

// update Contact
export function updateContact(contact) {
  return request({
    url: "/customer/contact-update",
    method: "post",
    data: contact,
  });
}

// company customer list
export function getCompanyCustomers(query) {
  return request({
    url: "/company/company-customer-list",
    method: "post",
    params: query,
  });
}

// create customer location
export function createLocation(location) {
  return request({
    url: "/customer-location-breaks-configure",
    method: "post",
    data: location,
  });
}

// delete customer location and break
export function deleteLocation(location) {
  return request({
    url: "/customer-location-breaks-configure-delete",
    method: "post",
    data: location,
  });
}