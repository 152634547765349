import { getClientShifts, getShift, createShift, updateShift, deleteShift } from "@/api/shift";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all shifts
    getClientShifts({ commit }, user_id) {
      return new Promise((resolve, reject) => {
        getClientShifts(user_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get shift by id
    getShift({ commit }, id) {
      return new Promise((resolve, reject) => {
        getShift(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create shift
    createShift({ commit }, shift) {
      return new Promise((resolve, reject) => {
        createShift(shift)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update shift
    updateShift({ commit }, shift) {
      return new Promise((resolve, reject) => {
        updateShift(shift)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // delete shift & break by id
    deleteShift({ commit }, data) {
      return new Promise((resolve, reject) => {
        deleteShift(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
