export default [
  // *===============================================---*
  // *--------- Employee ---- ---------------------------------------*
  // *===============================================---*

  {
    path: "/job-posts",
    name: "job-post",
    meta: {
      resource: "Job Post",
      action: "list",
    },
    component: () => import("@/views/job-posts/index.vue"),
  },

  {
    path: "/job-post/create",
    name: "create-job",
    component: () => import("@/views/job-posts/create.vue"),
    meta: {
      resource: "Job Post",
      action: "create",
      navActiveLink: 'job-post',
    },
  },
  // this is begining templete section
  {
    path: "/job-post/view-job-templete",
    name: "view-job-templete",
    component: () => import("@/views/job-posts/job-templete.vue"),
    meta: {
      resource: "Job Post",
      action: "template list",
    },
  },
  {
    path: "/job-post/create-job-templete",
    name: "create-job-templete",
    component: () => import("@/views/job-posts/job-templete-create.vue"),
    meta: {
      resource: "Job Post",
      action: "create template",
      navActiveLink: 'view-job-templete',
    },
  },
  {
    path: "/job/get-template/:id",
    name: "job-templete-preview",
    component: () => import("@/views/job-posts/job-templete-show.vue"),
    meta: {
      resource: "Job Post",
      action: "view template",
      navActiveLink: 'view-job-templete',
    },
  },
  {
    path: "/job/get-template-edit/:id",
    name: "job-templete-edit",
    component: () => import("@/views/job-posts/job-templete-edit.vue"),
    meta: {
      resource: "Job Post",
      action: "update template",
      navActiveLink: 'view-job-templete',
    },
  },
  // this end is templete section
  {
    path: "/job-post/preview/:id",
    name: "job-preview",
    component: () => import("@/views/job-posts/show.vue"),
    meta: {
      resource: "Job Post",
      action: "view",
      navActiveLink: 'job-post',
    },
  },
  {
    path: "/job-post/edit/:id",
    name: "job-edit",
    component: () => import("@/views/job-posts/edit.vue"),
    meta: {
      resource: "Job Post",
      action: "update",
      navActiveLink: 'job-post',
    },
  },
  {
    path: "/job-post/apply/:id",
    name: "job-apply",
    component: () => import("@/views/job-posts/apply.vue"),
    meta: {
      resource: "Job Post",
      action: "apply",
      navActiveLink: 'job-post',
    },
  },
  {
    path: "/job-post/status/:id",
    name: "job-status",
    component: () => import("@/views/job-posts/job-status.vue"),
    meta: {
      resource: "Job Post",
      action: "update status bulk",
      navActiveLink: 'job-post',
    },
  },
  {
    path: "/job-post/drafts",
    name: "job-post-draft",
    component: () => import("@/views/job-posts/drafts.vue"),
    meta: {
      resource: "Job Post",
      action: "draft list",
    },
  },
  {
    path: "/job-post/history",
    name: "job-post-history",
    component: () => import("@/views/job-posts/previous.vue"),
    meta: {
      resource: "Job Post",
      action: "job history",
    },
  },
  {
    path: "/job-posts/applicants/:id",
    name: "job-post-applicants",
    component: () => import("@/views/job-posts/applicant-list.vue"),
    meta: {
      resource: "Job Post",
      action: "view applicants",
      navActiveLink: 'job-post',
    },
  },
  {
    path: "/job-post/applied-jobs",
    name: "job-post-applied-jobs",
    component: () => import("@/views/job-posts/applied-jobs.vue"),
    meta: {
      resource: "Job Post",
      action: "view applied jobs",
    },
  },
];
