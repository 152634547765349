import {
  createRoster,
  getRosterbyId,
  publishRoster,
  rosterFilter,
  rosterWorkApprove,
  acceptDecline,
  checkEmployeeAvailability,
  customCreateTimesheetApi,
  getTimesheet,
  getTimesheetsForExport,
  approveAllTimesheets,
} from "@/api/roster";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // roster filter
    rosterFilter({ commit }, query) {
      return new Promise((resolve, reject) => {
        rosterFilter(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //  roster work approve
    rosterWorkApprove({ commit }, data) {
      return new Promise((resolve, reject) => {
        rosterWorkApprove(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create roster
    createRoster({ commit }, roster) {
      return new Promise((resolve, reject) => {
        createRoster(roster)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // publish roster
    publishRoster({ commit }, query) {
      return new Promise((resolve, reject) => {
        publishRoster(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // accept decline
    acceptDecline({ commit }, query) {
      return new Promise((resolve, reject) => {
        acceptDecline(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get roster by id
    getRosterbyId({ commit }, query) {
      return new Promise((resolve, reject) => {
        getRosterbyId(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // check employee availability
    checkEmployeeAvailability({ commit }, query) {
      return new Promise((resolve, reject) => {
        checkEmployeeAvailability(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get roster by id
    customCreateTimesheet({ commit }, data) {
      return new Promise((resolve, reject) => {
        customCreateTimesheetApi(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // apprve all timesheets
    approveAllTimesheets({ commit }, query) {
      return new Promise((resolve, reject) => {
        approveAllTimesheets(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });

    },

    // get timesheet by id
    getTimesheet({ commit }, id) {
      return new Promise((resolve, reject) => {
        getTimesheet(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get all the timesheets for export
    getTimesheetsForExport({ commit }, query) {
      return new Promise((resolve, reject) => {
        getTimesheetsForExport(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
};
