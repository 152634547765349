export default [
  {
    path: "/time-sheets",
    name: "time-sheets",
    component: () => import("@/views/time-sheet/index.vue"),
    meta: {
      resource: "Timesheet",
      action: "list",
    },
  },
  {
    path: "/time-sheets/create",
    name: "create-timesheet",
    component: () => import("@/views/time-sheet/create.vue"),
    meta: {
      resource: "Timesheet",
      action: "create",
    },
  },
  {
    path: "/time-sheets/edit/:id",
    name: "update-timesheet",
    component: () => import("@/views/time-sheet/edit.vue"),
    meta: {
      resource: "Timesheet",
      action: "update",
    },
  },
];
