import request from "@/utils/request";

// create roster
export function createRoster(roster) {
  return request({
    url: "/roster/store",
    method: "post",
    data: roster,
  });
}

// roster filter
export function rosterFilter(query) {
  return request({
    url: "/roster/filter",
    method: "post",
    params: query,
  });
}

// publish roster
export function publishRoster(query) {
  return request({
    url: "/roster/status-update",
    method: "post",
    params: query,
  });
}

// publish roster
export function acceptDecline(query) {
  return request({
    url: "/roster/employee-accept-decline",
    method: "post",
    params: query,
  });
}

// get company by id
export function getRosterbyId(query) {
  return request({
    url: "/roster/roster-work-list",
    method: "post",
    params: query,
  });
}
export function rosterWorkApprove(data) {
  return request({
    url: "/roster/roster-work-approved",
    method: "post",
    data,
  });
}

// check employee availability
export function checkEmployeeAvailability(query) {
  return request({
    url: "/roster/employee-availability-check",
    method: "post",
    params: query,
  });
}

// employee custom create timesheet
export function customCreateTimesheetApi(data) {
  return request({
    url: "roster/custom-create-timesheet",
    method: "post",
    data,
  });
}

// get timesheet by id
export function getTimesheet(id) {
  return request({
    url: "/roster/timesheet-view",
    method: "post",
    params: { id },
  });
}

// get timesheets for export
export function getTimesheetsForExport(query) {
  return request({
    url: "/roster/timesheet-export",
    method: "post",
    params: query,
  });
}

// approve all timesheets
export function approveAllTimesheets(query) {
  return request({
    url: "/roster/roster-work-approved-all",
    method: "post",
    data: query,
  });
}