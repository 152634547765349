import request from "@/utils/request";
import { ex } from "@fullcalendar/core/internal-common";

// get all shifts
export function getClientShifts(user_id) {
  return request({
    url: "/roster/shift-break-list",
    method: "post",
    data: {user_id: user_id},
  });
}

// get shift by id
export function getShift(id) {
  return request({
    url: "/shift/info",
    method: "post",
    params: { id },
  });
}

// create shift
export function createShift(shift) {
  return request({
    url: "/roster/shift-break-configuration",
    method: "post",
    data: shift,
  });
}

// update shift
export function updateShift(shift) {
  return request({
    url: "/shift/update",
    method: "post",
    data: shift,
  });
}

// delete shift & break by id
export function deleteShift(data) {
  return request({
    url: "/roster/shift-break-configuration-delete",
    method: "post",
    data: data,
  });
}
