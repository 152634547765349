import {
  getDesignations,
  getDesignation,
  createDesignation,
  updateDesignation,
} from "@/api/designation";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all designations
    getDesignations({ commit }, query) {
      return new Promise((resolve, reject) => {
        getDesignations(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get designation by id
    getDesignation({ commit }, id) {
      return new Promise((resolve, reject) => {
        getDesignation(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create designation
    createDesignation({ commit }, designation) {
      return new Promise((resolve, reject) => {
        createDesignation(designation)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update designation
    updateDesignation({ commit }, designation) {
      return new Promise((resolve, reject) => {
        updateDesignation(designation)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
