import {
  getSubLocations,
  getSubLocation,
  createSubLocation,
  updateSubLocation,
  getAllSubLocations,
} from "@/api/sub-location";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get list subLocation
    getSubLocations({ commit }, query) {
      return new Promise((resolve, reject) => {
        getSubLocations(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get all subLocations for dropdown
    getAllSubLocations({ commit }, location_id) {
      return new Promise((resolve, reject) => {
        getAllSubLocations(location_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get subLocations by id
    getSubLocation({ commit }, id) {
      return new Promise((resolve, reject) => {
        getSubLocation(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create subLocations
    createSubLocation({ commit }, subLocations) {
      return new Promise((resolve, reject) => {
        createSubLocation(subLocations)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update subLocations
    updateSubLocation({ commit }, subLocations) {
      return new Promise((resolve, reject) => {
        updateSubLocation(subLocations)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
