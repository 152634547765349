import { getRoles, getRole, getPermissionsAPI, createShift, assignPermissionsApi, updateShift } from "@/api/role";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all shifts
    getRoles({ commit }, query) {
      return new Promise((resolve, reject) => {
        getRoles(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get shift by id
    getRole({ commit }, id) {
      return new Promise((resolve, reject) => {
        getRole(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getPermissions(){
      // console.log("permission call");
      return new Promise((resolve, reject) => {
        getPermissionsAPI()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //create shift
    assignPermissions({ commit }, permissions) {
      return new Promise((resolve, reject) => {
        assignPermissionsApi(permissions)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update shift
    // updateShift({ commit }, shift) {
    //   return new Promise((resolve, reject) => {
    //     updateShift(shift)
    //       .then((response) => {
    //         const { data } = response;
    //         resolve(data);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
  },
};
