import {
  getFields,
  getField,
  createField,
  updateField,
} from "@/api/field";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all fields
    getFields({ commit }, query) {
      return new Promise((resolve, reject) => {
        getFields(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get field by id
    getField({ commit }, id) {
      return new Promise((resolve, reject) => {
        getField(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create field
    createField({ commit }, field) {
      return new Promise((resolve, reject) => {
        createField(field)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update field
    updateField({ commit }, field) {
      return new Promise((resolve, reject) => {
        updateField(field)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
