import request from "@/utils/request";

// get  locations list
export function getLocations(query) {
  return request({
    url: "/location/list",
    method: "post",
    params: query,
  });
}

// get all locations for dropdown
export function getAllLocations() {
  return request({
    url: "/location/all",
    method: "post",
  });
}

// get locations list for dropdown
export function getLocationsDropdown(query) {
  return request({
    url: "/location-has-lat-lng",
    method: "post",
    params: query,
  });
}

// get location by id
export function getLocation(id) {
  return request({
    url: "/location/info",
    method: "post",
    params: { id },
  });
}

// get client location by id
export function getClientLocations(id) {
  return request({
    url: "/customer-location-breaks-list",
    method: "post",
    params: { customer_id: id },
  });
}

// create location
export function createLocation(location) {
  return request({
    url: "/location/store",
    method: "post",
    data: location,
  });
}

// update location
export function updateLocation(location) {
  return request({
    url: "/location/update",
    method: "post",
    data: location,
  });
}
