import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn,
} from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";
import company from "./routes/company";
import customer from "./routes/customer";
import dashboard from "./routes/dashboard";
import employee from "./routes/employee";
import jobs from "./routes/jobs";
import pages from "./routes/pages";
import roster from "./routes/roster";
import settings from "./routes/settings";
import timesheet from "./routes/timesheet";
import users from "./routes/users";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard" } },
    // ...apps,
    ...dashboard,
    ...pages,
    ...roster,
    ...timesheet,
    ...employee,
    ...jobs,
    ...customer,
    ...company,
    ...settings,
    ...users,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});


router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (to.path === "/dashboard" && !isLoggedIn) {
    return next({ name: "auth-login" });
  }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });
    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(
      getHomeRouteForLoggedInUser(
        userData ? userData.roles[0].name : "employee"
      )
    );
  }
  return next();
});

export default router;
