import request from "@/utils/request";

// get all industries
export function getIndustries(query) {
  return request({
    url: "/industry/list",
    method: "post",
    params: query,
  });
}

// get industry by id
export function getIndustry(id) {
  return request({
    url: "/industry/info",
    method: "post",
    params: { id },
  });
}

// create industry
export function createIndustry(industry) {
  return request({
    url: "/industry/store",
    method: "post",
    data: industry,
  });
}

// update industry
export function updateIndustry(industry) {
  return request({
    url: "/industry/update",
    method: "post",
    data: industry,
  });
}
