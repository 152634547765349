import request from "@/utils/request";

// get all skills
export function getQuestionsAPI(query) {
  return request({
    url: "employee/question-list",
    method: "post",
    params: query,
  });
}

// get skill by id
// export function getSkill(id) {
//   return request({
//     url: "/skill/info",
//     method: "post",
//     params: { id },
//   });
// }

// create skill
// export function createSkill(skill) {
//   return request({
//     url: "/skill/store",
//     method: "post",
//     data: skill,
//   });
// }

// update skill
// export function updateSkill(skill) {
//   return request({
//     url: "/skill/update",
//     method: "post",
//     data: skill,
//   });
// }
