export default [
  {
    path: "/companies",
    name: "companies",
    meta: {
      resource: "Company",
      action: "list",
    },
    component: () => import("@/views/company/index.vue"),
  },
  {
    path: "/company-create",
    name: "Add Company",
    meta: {
      resource: "Company",
      action: "create",
      navActiveLink: 'companies',
    },
    component: () => import("@/views/company/create.vue"),
  },
  {
    path: "/company/supervisor-create/:id",
    name: "supervisor-create",
    component: () => import("@/views/company/supervisor/create.vue"),
    meta: {
      resource: "Supervisor",
      action: "create",
      navActiveLink: 'companies',
    },
  },
  {
    path: "/company/supervisor-edit/:id/:supervisor_id",
    name: "supervisor-edit",
    component: () => import("@/views/company/supervisor/edit.vue"),
    meta: {
      resource: "Supervisor",
      action: "update",
      navActiveLink: 'companies',
    },
  },

  {
    path: "/company/supervisor-view/:id/:supervisor_id",
    name: "supervisor-view",
    component: () => import("@/views/company/supervisor/view.vue"),
    meta: {
      resource: "Supervisor",
      action: "update",
      navActiveLink: 'companies',
    },
  },

  {
    path: "/company/view/:id",
    name: "company/view",
    meta: {
      resource: "Company",
      action: "view",
      navActiveLink: 'companies',
    },
    component: () => import("@/views/company/show.vue"),
  },
  {
    path: "/company/edit/:id",
    name: "edit-company-profile",
    meta: {
      resource: "Company",
      action: "update",
      navActiveLink: 'companies',
    },
    component: () => import("@/views/company/edit.vue"),
  },
  {
    path: "/import-company",
    name: "Import Company",
    meta: {
      resource: "Company",
      action: "import",
      navActiveLink: 'companies',
    },
    component: () => import("@/views/company/import.vue"),
  },
];
