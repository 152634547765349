import {
  getBanks,
  getBank,
  createBank,
  updateBank,
} from "@/api/bank";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all banks
    getBanks({ commit }, query) {
      return new Promise((resolve, reject) => {
        getBanks(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get bank by id
    getBank({ commit }, id) {
      return new Promise((resolve, reject) => {
        getBank(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create bank
    createBank({ commit }, bank) {
      return new Promise((resolve, reject) => {
        createBank(bank)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update bank
    updateBank({ commit }, bank) {
      return new Promise((resolve, reject) => {
        updateBank(bank)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
