export default [
  {
    path: "/customers",
    name: "Client List",
    meta: {
      resource: "Client",
      action: "list",
    },
    component: () => import("@/views/customer/index.vue"),
  },
  {
    path: "/customer/create",
    name: "Add Client",
    meta: {
      resource: "Client",
      action: "create",
      navActiveLink: 'Client List',
    },
    component: () => import("@/views/customer/create.vue"),
  },
  {
    path: "/customer/view/:id",
    name: "Client/view",
    meta: {
      resource: "Client",
      action: "view",
      navActiveLink: 'Client List',
    },
    component: () => import("@/views/customer/show.vue"),
  },
  {
    path: "/customer/edit/:id",
    name: "Client/edit",
    meta: {
      resource: "Client",
      action: "update",
      navActiveLink: 'Client List',
    },
    component: () => import("@/views/customer/edit.vue"),
  },
  {
    path: "/import-customer",
    name: "Import Client",
    meta: {
      resource: "Client",
      action: "create",
      navActiveLink: 'Client List',
    },
    component: () => import("@/views/customer/import.vue"),
  },
  {
    path: "/client-location-break-config",
    name: "client-location-break-config",
    meta: {
      resource: "Client",
      action: "location break configuration",
    },
    component: () => import("@/views/customer-location/index.vue"),
  },
];
