export default [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      resource: "Dashboard",
      action: "show menu",
    },
    component: () =>
      import("@/views/dashboard/index.vue"),
  },
];
