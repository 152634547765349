import request from "@/utils/request";

// get all categorys
export function getCategories(query) {
  return request({
    url: "/category/list",
    method: "post",
    params: query,
  });
}

// get category by id
export function getCategory(id) {
  return request({
    url: "/category/info",
    method: "post",
    params: { id },
  });
}

// create category
export function createCategory(category) {
  return request({
    url: "/category/store",
    method: "post",
    data: category,
  });
}

// update category
export function updateCategory(category) {
  return request({
    url: "/category/update",
    method: "post",
    data: category,
  });
}
