import request from "@/utils/request";

// get all Roles
export function getRoles(query) {
  return request({
    url: "/role-list",
    method: "post",
    params: query,
  });
}
//get all permissions
export function getPermissionsAPI(query) {
  return request({
    url: "/permission-list-only",
    method: "post",
    params: query,
  });
}

// get shift by id
export function getRole(id) {
  return request({
    url: "/get-single-role",
    method: "post",
    params: { id },
  });
}

// create shift
export function assignPermissionsApi(permissions) {
  return request({
    url: "/role-permission-assign",
    method: "post",
    data: permissions,
  });
}

// update shift
export function updateShift(shift) {
  return request({
    url: "/shift/update",
    method: "post",
    data: shift,
  });
}
