import {
  getAccountTypes,
  getAccountType,
  createAccountType,
  updateAccountType,
} from "@/api/account-type";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all accountTypes
    getAccountTypes({ commit }, query) {
      return new Promise((resolve, reject) => {
        getAccountTypes(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get accountType by id
    getAccountType({ commit }, id) {
      return new Promise((resolve, reject) => {
        getAccountType(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create accountType
    createAccountType({ commit }, accountType) {
      return new Promise((resolve, reject) => {
        createAccountType(accountType)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update accountType
    updateAccountType({ commit }, accountType) {
      return new Promise((resolve, reject) => {
        updateAccountType(accountType)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
