import {
  getDuties,
  getDuty,
  createDuty,
  updateDuty,
  getDutyForRegister
} from "@/api/duty";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all Duties
    getDuties({ commit }, query) {
      return new Promise((resolve, reject) => {
        getDuties(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get duty by id
    getDuty({ commit }, id) {
      return new Promise((resolve, reject) => {
        getDuty(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDutyForRegister(){
      return new Promise((resolve, reject) => {
        getDutyForRegister()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create duty
    createDuty({ commit }, duty) {
      return new Promise((resolve, reject) => {
        createDuty(duty)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update duty
    updateDuty({ commit }, duty) {
      return new Promise((resolve, reject) => {
        updateDuty(duty)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
