import request from "@/utils/request";

// get list subLocations
export function getSubLocations(query) {
  return request({
    url: "/sub-location/list",
    method: "post",
    params: query,
  });
}

// get all subLocations for dropdown
export function getAllSubLocations(location_id) {
  return request({
    url: "/sub-location/all",
    method: "post",
    params: { location_id },
  });
}

// get subLocation by id
export function getSubLocation(id) {
  return request({
    url: "/sub-location/info",
    method: "post",
    params: { id },
  });
}

// create subLocation
export function createSubLocation(subLocation) {
  return request({
    url: "/sub-location/store",
    method: "post",
    data: subLocation,
  });
}

// update subLocation
export function updateSubLocation(subLocation) {
  return request({
    url: "/sub-location/update",
    method: "post",
    data: subLocation,
  });
}
