import {
  getSectors,
  getSector,
  createSector,
  updateSector,
} from "@/api/sector";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all sectors
    getSectors({ commit }, query) {
      return new Promise((resolve, reject) => {
        getSectors(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get sector by id
    getSector({ commit }, id) {
      return new Promise((resolve, reject) => {
        getSector(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create sector
    createSector({ commit }, sector) {
      return new Promise((resolve, reject) => {
        createSector(sector)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update sector
    updateSector({ commit }, sector) {
      return new Promise((resolve, reject) => {
        updateSector(sector)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
