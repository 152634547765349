import request from "@/utils/request";

// get all companies
export function getJobs(query) {
  return request({
    url: "/job/list",
    method: "post",
    params: query,
  });
}
export function getJobsDraft(query) {
  return request({
    url: "/job/job-draft-list",
    method: "post",
    params: query,
  });
}

// get job by id
export function getJob(query) {
  return request({
    url: "/job/job-details",
    method: "post",
    params: query,
  });
}

// get job hsiory
export function getJobHistory(query) {
  return request({
    url: "job/expire-job-list",
    method: "post",
    params: query,
  });
}
// job employee selection
export function changeApplicanStatus(data) {
  return request({
    url: "/job/employee-selection",
    method: "post",
    data,
  });
}
// get job by id
export function deleteJob(id) {
  return request({
    url: "/job/delete",
    method: "post",
    params: { id },
  });
}

// store job
export function storeJob(data) {
  return request({
    url: "/job/store",
    method: "post",
    data,
  });
}
// create templete
export function storeJobTemplete(data) {
  return request({
    url: "/job/job-template-store",
    method: "post",
    data,
  });
}
export function getJobTemplete(category_id) {
  return request({
    url: "/job/get-template",
    method: "post",
    params: {category_id},
  });
}

// update job
export function updateJob(data) {
  return request({
    url: "/job/update",
    method: "post",
    data,
  });
}

// publish job  
export function publishJob(data) {
  return request({
    url: "/job/job-status-update",
    method: "post",
    data,
  });
}

// apply job
export function applyJob(data) {
  return request({
    url: "/job-apply-without-login",
    method: "post",
    data,
  });
}

// get job applicants
export function getApplicants(id) {
  return request({
    url: "/job/job-apply-list",
    method: "post",
    params: { id },
  });
}

// update job status
export function updateStatus(data) {
  return request({
    url: "/job/job-progress-update",
    method: "post",
    data,
  });
}

// get applied jobs
export function getAppliedJobs(query) {
  return request({
    url: "job/job-apply-list",
    method: "post",
    params: query,
  });
}

// update job proiority
export function updatePriority(data) {
  return request({
    url: "/job/employee-set-priority",
    method: "post",
    params: data,
  });
}

// apply job
export function apply(data) {
  return request({
    url: "/job/job-apply",
    method: "post",
    data,
  });
}
