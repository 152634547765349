import request from "@/utils/request";

// get all designations
export function getDesignations(query) {
  return request({
    url: "/designation/list",
    method: "post",
    params: query,
  });
}

// get designation by id
export function getDesignation(id) {
  return request({
    url: "/designation/info",
    method: "post",
    params: { id },
  });
}

// create designation
export function createDesignation(designation) {
  return request({
    url: "/designation/store",
    method: "post",
    data: designation,
  });
}

// update designation
export function updateDesignation(designation) {
  return request({
    url: "/designation/update",
    method: "post",
    data: designation,
  });
}
