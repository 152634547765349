import request from "@/utils/request";

// get all positions
export function getPositions(query) {
  return request({
    url: "/position/list",
    method: "post",
    params: query,
  });
}

// get position by id
export function getPosition(id) {
  return request({
    url: "/position/info",
    method: "post",
    params: { id },
  });
}

// create position
export function createPosition(position) {
  return request({
    url: "/position/store",
    method: "post",
    data: position,
  });
}

// update position
export function updatePosition(position) {
  return request({
    url: "/position/update",
    method: "post",
    data: position,
  });
}
