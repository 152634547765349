import {
  companyWiseEmployeeCount,
  customerTimesheetHoursCountMonths,
  getEmployeeEngagement,
  getTotal,
  timesheetHoursCount,
  employeeDocumentExpire,
  customerDashboardData,
  getEmployeesExpiry,
} from "@/api/dashboard";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    timesheetHoursCount({ commit }, company_id) {
      return new Promise((resolve, reject) => {
        timesheetHoursCount(company_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // customer timesheet hours count month
    customerTimesheetHoursCountMonths({ commit }, customer_id) {
      return new Promise((resolve, reject) => {
        customerTimesheetHoursCountMonths(customer_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // companyWiseEmployeeCount
    companyWiseEmployeeCount({ commit }, company_id) {
      return new Promise((resolve, reject) => {
        companyWiseEmployeeCount(company_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get total
    getTotal({ commit }) {
      return new Promise((resolve, reject) => {
        getTotal()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get dashboard employee engagement
    getEmployeeEngagement({ commit }) {
      return new Promise((resolve, reject) => {
        getEmployeeEngagement()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // employeeDocumentExpire
    employeeDocumentExpire({ commit }, user_id) {
      return new Promise((resolve, reject) => {
        employeeDocumentExpire(user_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // customerDashboardData
    customerDashboardData({ commit }) {
      return new Promise((resolve, reject) => {
        customerDashboardData()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get dashboard employee document expire
    getEmployeesExpiry({ commit }, query) {
      return new Promise((resolve, reject) => {
        getEmployeesExpiry(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
};
