import {
  changePassword,
  forgetPassword,
  login,
  register,
  verifyOTP,
} from "@/api/auth";
import { getLogedInUser } from "@/utils/auth";
export default {
  namespaced: true,
  state: {
    token: null,
    user: getLogedInUser(),
    abilities: [],
    passwordResetMail: null,
  },
  getters: {},
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ABILITIES(state, abilities) {
      state.abilities = abilities;
    },
    SET_PASSWORD_RESET_MAIL(state, passwordResetMail) {
      state.passwordResetMail = passwordResetMail;
    },
  },
  actions: {
    // user login
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        login(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // user register
    register({ commit }, data) {
      return new Promise((resolve, reject) => {
        register(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // forget password request mail
    forgetPassword({ commit }, data) {
      commit("SET_PASSWORD_RESET_MAIL", data.email);
      return new Promise((resolve, reject) => {
        forgetPassword(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // reset password
    changePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        changePassword(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    verifyOTP({ commit }, data) {
      return new Promise((resolve, reject) => {
        verifyOTP(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
