import request from "@/utils/request";

// get all fields
export function getFields(query) {
  return request({
    url: "/field/list",
    method: "post",
    params: query,
  });
}

// get field by id
export function getField(id) {
  return request({
    url: "/field/info",
    method: "post",
    params: { id },
  });
}

// create field
export function createField(field) {
  return request({
    url: "/field/store",
    method: "post",
    data: field,
  });
}

// update field
export function updateField(field) {
  return request({
    url: "/field/update",
    method: "post",
    data: field,
  });
}
