import {
  activeUser,
  createUser,
  getUser,
  getUsers,
  notification,
  readNotification,
  statusChange,
  suspendUser,
  updateUser,
  updateUserPassword,
  deleteUser,
} from "@/api/user";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all users
    getUsers({ commit }, query) {
      return new Promise((resolve, reject) => {
        getUsers(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        getUser(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    suspendUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        suspendUser(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // delete
    deleteUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        deleteUser(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // statusChange
    statusChange({ commit }, data) {
      return new Promise((resolve, reject) => {
        statusChange(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    activeUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        activeUser(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        createUser(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateUser({ commit }, skill) {
      return new Promise((resolve, reject) => {
        updateUser(skill)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateUserPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateUserPassword(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    notification({ commit }, query) {
      return new Promise((resolve, reject) => {
        notification(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // readNotification
    readNotification({ commit }, data) {
      return new Promise((resolve, reject) => {
        readNotification(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
