import request from "@/utils/request";

// get all sectors
export function getSectors(query) {
  return request({
    url: "/sector/list",
    method: "post",
    params: query,
  });
}

// get sector by id
export function getSector(id) {
  return request({
    url: "/sector/info",
    method: "post",
    params: { id },
  });
}

// create sector
export function createSector(sector) {
  return request({
    url: "/sector/store",
    method: "post",
    data: sector,
  });
}

// update sector
export function updateSector(sector) {
  return request({
    url: "/sector/update",
    method: "post",
    data: sector,
  });
}
