import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import { getToken } from "@/utils/auth";
import { toast } from "@/utils/toast";
import axios from "axios";
import { VUE_APP_BASE_URL } from "@/utils/var";

// create an axios instance
const service = axios.create({
  baseURL: VUE_APP_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    let token = getToken();
    // do something before request is sent
    if (token) {
      // let each request carry token
      // please modify it according to the actual situation
      (config.headers["Access-Control-Allow-Headers"] = "*"),
        (config.headers["Access-Control-Allow-Origin"] = "*"),
        (config.headers["Accept"] = "application/json"),
        (config.headers["Content-Type"] = "application/json"),
        (config.headers["Authorization"] = "Bearer " + token);
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log("error", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async (response) => {
    const res = response;
    // if the custom status is not 200, it is judged as an error.
    if (res.status !== 200) {
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  async (error) => {
    const res = error.response;
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    if (res?.status === 401) {
      if (res?.data?.message !== 'Please Login'){
        toast(
          "Login Failed!",
          "AlertCircleIcon",
          "danger",
          res?.data?.message || "Type correct login credential."
        );
      }
        
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Redirect to login page
      router.push({ name: "auth-login" });
    }
    let errors = res?.data?.error;
    if(errors instanceof Array && errors && errors.length > 0){
      let errorData = Object.values(errors)
      errorData.forEach((element) => {
        element.forEach((item) => {
          toast(
            "Login Failed!",
            "AlertCircleIcon",
            "danger",
            item || "Type correct login credential."
          );
        });
      });
    }
    return Promise.reject(error);
  }
);

export default service;
