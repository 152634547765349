// for development environment
export const VUE_IMG_BASE_URL = "https://grypas.inflack.xyz/grypas-api/";
export const VUE_APP_URL= "https://grypas.inflack.xyz"

// for production environment shiftplanner
// export const VUE_IMG_BASE_URL = "https://api-webapp.shiftplanner.com.au/";
// export const VUE_APP_URL= "https://webapp.shiftplanner.com.au"



export const VUE_APP_BASE_URL= VUE_IMG_BASE_URL + "api/v1"
