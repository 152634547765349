import {
  getIndustries,
  getIndustry,
  createIndustry,
  updateIndustry,
} from "@/api/industry";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all categories
    getIndustries({ commit }, query) {
      return new Promise((resolve, reject) => {
        getIndustries(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get industry by id
    getIndustry({ commit }, id) {
      return new Promise((resolve, reject) => {
        getIndustry(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create industry
    createIndustry({ commit }, industry) {
      return new Promise((resolve, reject) => {
        createIndustry(industry)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update industry
    updateIndustry({ commit }, industry) {
        return new Promise((resolve, reject) => {
            updateIndustry(industry)
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
  },
};
